import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import * as React from "react";
import AboutViewModel from "../../viewModels/about/aboutViewModel";

const AboutView: ViewComponent<AboutViewModel> = observer(({ vm }) => {
  return (
    <>
      <h1 className="jetel">Včelařova včelnice</h1>
      <p>
        Včelnice se nachází v katastru Vratislavic nad Nisou nedaleko Mojžíšova pramene. Lokalita je součástí Maršovické
        vrchoviny, která geomorfologicky spadá do Jizerských hor. Tato oblast není zasažena intenzivní zemědělskou činností a
        tento fakt se pozitivně promítá do kvality medu.
      </p>
      <p>
        Včelstva sice nedosahují vysokých medných výnosů, ale odměnou zato je kvalitní med bez reziduí pesticidů. Absence
        chemických postřiků v krajině vyhovuje včelám a nedochází k jejich zbytečnému stresování.{" "}
      </p>
      <p>
        V okolí stanoviště včel jsou trvalé travní porosty, které jsou spásány dobytkem nebo sečeny místními drobnými zemědělci.
        Na tyto trvalé travní porosty navazuje smíšený lesní porost, který zajišťuje včelám v blízkém doletu kvalitní a pestrou
        pastvu po celou včelařskou sezónu. V okrajích a mýtinách těchto lesních porostů je hojný výskyt maliníku a ostružiníku,
        který včely milují. Tento les v těsném sousedství včelnice pravidelně zajišťuje medovicovou snůšku a dostatek tmavého
        medu.
      </p>
    </>
  );
});

registerView(AboutView, AboutViewModel);
