import React from "react";
import { Navbar } from "react-bootstrap";

export interface HeaderProps {
  children: React.ReactNode;
}

export default function Header(props: HeaderProps) {
  return (
    <Navbar collapseOnSelect expand="lg" as="header" className="header">
      <div className="container">
        <Navbar.Brand href={undefined}>
          <div className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">{props.children}</Navbar.Collapse>
      </div>
    </Navbar>
  );
}
