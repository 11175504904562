import React from "react";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import ProductsViewModel from "../../viewModels/products/productsViewModel";

const ProductsView: ViewComponent<ProductsViewModel> = observer(({ vm }) => {
  return (
    <>
      <h1 className="rozperak">Produkty včel</h1>
      <p>
        <strong>Med</strong> – včelí med je obecně nejznámější a nejdůležitější včelí produkt. Med vytvářejí včely z nektaru květů
        nebo z medovice. Obohacují jej výměšky svých hltanových žláz, snižují obsah vody a zralý uskladňují v plástech. Med od
        Mojžíšova pramene je z velké části spotřebováván rodinou včelaře. Pouze přebytky produkce jsou formou prodeje ze dvora
        prodávány případným zájemců. Tento fakt se podepisuje na kvalitě produkce medu. Veškerý produkovaný Med od Mojžíšova
        pramene je vytáčen pouze z panenských plástů nebo včelami nově vystavěných plástu. Tyto medníkové plásty nikdy nepřišly do
        kontaktu s léčivy, které jsou ve včelařství používány k potlačení kleštíka včelího (Varroa destructor). Tento roztoč se
        stal součástí našich včelstev a je včelařovou povinností pomáhat včelám držet tohoto neřáda na uzdě. Díky spoustě faktorů
        jako jsou například: technika vedení včelstev, zásahy včelaře, pestrá pastva pro včely a absence chemických postřiků v
        krajině se většinou daří kleštíka (Varroa destructor) držet v únosné mezi a nedochází ke ztrátám včelstev. Včelařovu snahu
        ocenila agentura Masif udělením certifikátu Regionální produkt – Jizerské hory.
      </p>
      <p>
        <strong>Plástečkový med</strong> – produkt nejvyšší kvality na jehož vzniku se podílejí pouze včely. Vykusováním a
        následným žvýkáním plástečkového medu konzument ochutná maximálně plnou chuť medu obohacenou o propolis a vosk. Případná
        konzumace vosku není škodlivá. Vosk projde zažívacím traktem a lidské tělo jej bez problémů vyloučí. V dřívějších dobách
        před vynálezem medometu se med získával vykapáváním plástů, jejich lisováním a na závěr vykusováním. Tento lahodný
        gurmánský požitek může zájemce vyzkoušet na vlastní kůži zakoupením plástečkového medu.{" "}
      </p>
      <p>
        <strong>Včelí vosk</strong> - Včelí vosk je metabolický produkt včely, který se tvoří ve voskotvorné žláze včely dělnice,
        jejímž vnějším zakončením jsou voskotvorná zrcadélka na třetím, čtvrtém, pátém a šestém zadečkovém článku. Z vosku včely
        stavějí plásty, do nichž ukládají zásoby a v nichž odchovávají plod. Vosk včelaři zpracovávají na mezistěny, výrobu svící
        a dále se využívá ve farmaceutickém a kosmetickém průmyslu.{" "}
      </p>
    </>
  );
});

registerView(ProductsView, ProductsViewModel);
