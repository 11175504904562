import React from "react";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import EshopViewModel from "../../viewModels/eshop/eshopViewModel";
import honey from "./med.png";
import certificate from "./certifikat-2021.png";

const EshopView: ViewComponent<EshopViewModel> = observer(({ vm }) => {
  return (
    <>
      <h1>Produkty na prodej</h1>
      <div className="row">
        <div className="col-md-4 text-center text-sm-left">
          <h3>Med od Mojžíšova pramene</h3>
          <span className="mb-2 d-block">950 g, 200 Kč</span>
          <span className="mb-4 d-block">500 g, 130 Kč</span>
          <p>
            Veškeré produkty na prodej jsou k dispozici <br />
            po telefonické dohodě na adrese: <br />
            Wintrova 488/18, Liberec 2
          </p>
        </div>
        <div className="col-md-8 d-sm-flex justify-content-md-center text-center">
          <img src={honey} className="honey" alt="Med od Mojžíšova pramenene 950g" />
          <img src={certificate} className="certificate" alt="Certifikát - Regionální produkt Jizerských Hor" />
        </div>
      </div>
    </>
  );
});

registerView(EshopView, EshopViewModel);
