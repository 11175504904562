import { View } from "@frui.ts/views";
import { Container } from "inversify";
import React from "react";
import ReactDOM from "react-dom";
import RootViewModel from "./viewModels/rootViewModel";

function renderViewModel(viewModel: any) {
  ReactDOM.render(<View vm={viewModel} useLifecycle />, document.getElementById("root"));
}

export default function runApp(container: Container) {
  const rootViewModel = container.get(RootViewModel);
  renderViewModel(rootViewModel);
}
