// WARNING: This file has been generated. Do not edit it manually, your changes might get lost.
import { Container } from "inversify";
import viewModelsAboutAboutViewModel from "./viewModels/about/aboutViewModel";
import viewModelsContactContactViewModel from "./viewModels/contact/contactViewModel";
import viewModelsEshopEshopViewModel from "./viewModels/eshop/eshopViewModel";
import viewModelsProductsProductsViewModel from "./viewModels/products/productsViewModel";
import viewModelsRootViewModel from "./viewModels/rootViewModel";

export default function registerServices(container: Container) {
  container.bind<viewModelsRootViewModel>(viewModelsRootViewModel).toSelf();
  container.bind<viewModelsAboutAboutViewModel>(viewModelsAboutAboutViewModel).toSelf();
  container.bind<viewModelsContactContactViewModel>(viewModelsContactContactViewModel).toSelf();
  container.bind<viewModelsEshopEshopViewModel>(viewModelsEshopEshopViewModel).toSelf();
  container.bind<viewModelsProductsProductsViewModel>(viewModelsProductsProductsViewModel).toSelf();
}
