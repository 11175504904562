import React from "react";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import Nav from "react-bootstrap/Nav";
import { observer, Observer } from "mobx-react-lite";
import Header from "../components/Header";

import RootViewModel from "../viewModels/rootViewModel";
import { Router } from "@frui.ts/screens";

const rootView: ViewComponent<RootViewModel> = observer(({ vm }) => {
  const getUrl = Router.getChildUrlFactory(vm);

  return (
    <>
      <Header>
        <Nav className="mr-auto menu">
          {vm.children.map((x: any, i: number) => (
            <Nav.Item key={x.name}>
              <Nav.Link
                href={getUrl(x)}
                id={`menu-${x.navigationName}`}
                active={vm.activeChild === x}
                eventKey={x.navigationName}
                onClick={(e: any) => {
                  e.preventDefault();
                  vm.tryActivateChild(x);
                }}>
                {x.name}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </Header>
      <div className="container content">
        <Observer>{() => <View vm={vm.activeChild} />}</Observer>
      </div>
      <div className="footer">
        <div className="bee mother" />
        <div className="bee emma" />
        <div className="bee sara" />
      </div>
    </>
  );
});

registerView(rootView, RootViewModel);
