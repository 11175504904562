import React from "react";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import ContactViewModel from "../../viewModels/contact/contactViewModel";

const ContactView: ViewComponent<ContactViewModel> = observer(({ vm }) => {
  return (
    <>
      <h1>Kontakt na včelaře</h1>
      <h3>Ing. Jiří Gajdoš</h3>
      <span>Wintrova 488/18, Liberec</span>
      <dl className="inline-flex">
        <dt>tel.:</dt>
        <dd>
          <a className="text-dark" href="tel:702397133">
            702 397 133
          </a>
        </dd>
        <dt>email:</dt>
        <dd>
          <a className="text-dark" href="mailto:vcelaruv-med@seznam.cz">
            vcelaruv-med@seznam.cz
          </a>
        </dd>
        <dt>www:</dt>
        <dd>
          <a className="text-dark" href="http://www.vcelaruv-med.cz">
            www.vcelaruv-med.cz
          </a>
        </dd>
      </dl>
    </>
  );
});

registerView(ContactView, ContactViewModel);
