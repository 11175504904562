// WARNING: This file has been generated. Do not edit it manually, your changes might get lost.
import { decorate, inject, injectable } from "inversify";
import viewModelsAboutAboutViewModel from "./viewModels/about/aboutViewModel";
import viewModelsContactContactViewModel from "./viewModels/contact/contactViewModel";
import viewModelsEshopEshopViewModel from "./viewModels/eshop/eshopViewModel";
import viewModelsProductsProductsViewModel from "./viewModels/products/productsViewModel";
import viewModelsRootViewModel from "./viewModels/rootViewModel";
decorate(injectable(), viewModelsRootViewModel);
decorate(inject(viewModelsAboutAboutViewModel) as any, viewModelsRootViewModel, 0);
decorate(inject(viewModelsProductsProductsViewModel) as any, viewModelsRootViewModel, 1);
decorate(inject(viewModelsEshopEshopViewModel) as any, viewModelsRootViewModel, 2);
decorate(inject(viewModelsContactContactViewModel) as any, viewModelsRootViewModel, 3);
decorate(injectable(), viewModelsAboutAboutViewModel);
decorate(injectable(), viewModelsContactContactViewModel);
decorate(injectable(), viewModelsEshopEshopViewModel);
decorate(injectable(), viewModelsProductsProductsViewModel);
