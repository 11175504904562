import { ConductorOneChildActive, ScreenBase } from "@frui.ts/screens";
import UrlNavigationAdapter from "@frui.ts/screens/dist/navigation/urlNavigationAdapter";
import AboutViewModel from "./about/aboutViewModel";
import ContactViewModel from "./contact/contactViewModel";
import EshopViewModel from "./eshop/eshopViewModel";
import ProductsViewModel from "./products/productsViewModel";

export default class RootViewModel extends ConductorOneChildActive<ScreenBase> {
  private urlNavigationAdapter: UrlNavigationAdapter;

  constructor(
    private aboutViewModel: AboutViewModel,
    private productsViewModel: ProductsViewModel,
    private eshopViewModel: EshopViewModel,
    private contactViewModel: ContactViewModel
  ) {
    super();
    this.children.push(this.aboutViewModel, this.productsViewModel, this.eshopViewModel, this.contactViewModel);
    this.urlNavigationAdapter = new UrlNavigationAdapter();
  }

  protected async onInitialize() {
    await this.urlNavigationAdapter.start(this);
    if (!this.activeChild && this.children.length) {
      return this.tryActivateChild(this.children[0]);
    }
  }

  closeChild() {
    // children cannot be closed
    return false;
  }
}
